<template>
    <div class="ng-header border-bottom-pos" :class="[isSearching ? 'search-open' : '']">
        <div class="ng-header-content container hidden-sm">
            <router-link class="svg-box w-100 h-100 logo" to="/musicFine">
                <img src="@/assets/images/web/home/logo.png" alt="logo" />
            </router-link>

            <div class="menu-list flex-1 pos-relative">
                <ul class="header-menu header-menu-left f-l">
                    <template v-for="item in Menu">
                        <li class="header-menu-item subscribe_introduce" :key="item.id"
                            v-if="item.to=='/subscribe_introduce'">
                            <a-dropdown placement="bottomCenter">
                                <router-link class="header-link" :to="item.to">{{
                  item.name
                }}</router-link>

                                <a-menu slot="overlay" style="width:747px;">
                                    <a-menu-item
                                        style="height:99px;width:100%;background: #FEF4EC;box-shadow: 0px 2px 6px 0px rgba(198, 198, 198, 0.23);border-radius: 2px 2px 0px 0px;">
                                        <a-row
                                            style="margin-top:23px;font-size: 18px;font-weight: 500;color: #333333;text-shadow: 0px 2px 6px rgba(198, 198, 198, 0.23);">
                                            授权类型</a-row>
                                        <a-row
                                            style="margin-top:8px;font-size: 14px;font-weight: 400;color: #333333;text-shadow: 0px 2px 6px rgba(198, 198, 198, 0.23);">
                                            支持单首音乐授权和VIP音乐授权（订阅曲库）；<br>购买VIP后，可免费下载会员专区音乐&音效库海量音效，无需额外付费。</a-row>
                                    </a-menu-item>
                                    <a-menu-item>
                                        <div class="intr-info">
                                            <div class="intr-info-item">
                                                <div>
                                                    <h3>单曲购买</h3>
                                                    <p>指定授权场景</p>
                                                </div>
                                                <div>
                                                    <p>选曲范围：线上曲库</p>
                                                    <p>低至元¥<span>70</span>/首</p>
                                                    <router-link :to="`/allmusic`">
                                                        <p>单曲购买</p>
                                                    </router-link>
                                                </div>
                                                <div>
                                                    <p>适合音乐需求量较少的企</p>
                                                    <p>业或个人自由选择场景</p>
                                                </div>
                                            </div>
                                            <div class="intr-info-item">
                                                <div>
                                                    <h3>个人VIP</h3>
                                                    <p>个人非商用</p>
                                                </div>
                                                <div>
                                                    <p>选曲范围：订阅曲库&音效库</p>
                                                    <p>低至元¥<span>299</span>/年</p>
                                                    <router-link :to="`/subscribe?vipType=2`">
                                                        <p>立即开通</p>
                                                    </router-link>
                                                </div>
                                                <div>
                                                    <p>适合学生、短视频博主、个</p>
                                                    <p>人自媒体账号、UP主等</p>
                                                </div>
                                            </div>
                                            <div class="intr-info-item">
                                                <div>
                                                    <h3>企业VIP</h3>
                                                    <p>企业商用</p>
                                                </div>
                                                <div>
                                                    <p>选曲范围：订阅曲库&音效库</p>
                                                    <p>低至元¥<span>3999</span>/年</p>
                                                    <router-link :to="`/subscribe?vipType=1`">
                                                        <p>立即开通</p>
                                                    </router-link>
                                                </div>
                                                <div>
                                                    <p>适合中小型企业，满足日常</p>
                                                    <p>高频音乐使用的需求</p>
                                                </div>
                                            </div>
                                            <div class="intr-info-item">
                                                <div>
                                                    <h3>企业定制</h3>
                                                    <p>企业商用</p>
                                                </div>
                                                <div>
                                                    <p>选曲范围：订阅曲库</p>
                                                    <p>定制高级音乐内容</p>
                                                    <p @click="Chatra">联系客服</p>
                                                </div>
                                                <div>
                                                    <p>适合大型企业/国企高级定</p>
                                                    <p>制，满足不同项目需求</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a-menu-item>
                                    <a-menu-item style="padding-top: 24px;padding-bottom: 24px;">
                                        <router-link :to="`/subscribe_introduce`">
                                            <div
                                                style="line-height: 50px;width:93.6%;margin:0 auto;background: #F2F4F7;border-radius: 2px;text-align:center;">
                                                <span
                                                    style="display:inline-block;font-size:18px;font-weight: 400;color: #4E6276;height:25px;">了解更多授权类型对比</span>
                                            </div>
                                        </router-link>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </li>
                        <li class="header-menu-item" :key="item.id" v-else>
                            <router-link class="header-link" :to="{path:item.to,query:{id:item.id}}">{{
                item.name
              }}
                                <sup v-if="item.name=='音效'" style="color:red">beta</sup>
                            </router-link>

                        </li>
                    </template>
                </ul>
            </div>

            <ul class="header-menu f-l">
                <li class="header-menu-item">
                    <router-link :to="`/subscribe_introduce_v2`">
                        <img src="@/assets/images/web/home/header_vip.svg" style="width: 26px;" />
                        <div class="subscribe-box">
                            <span>订阅会员</span>
                            <span>HOT</span>
                        </div>
                    </router-link>
                </li>
            </ul>

            <ul class="header-menu f-l">
                <template v-if="$store.state.login">
                    <li class="header-menu-item">
                        <a-badge :count="$store.state.goodNumOfCart" :overflow-count="99">
                            <router-link class="header-icon" to="/cart">
                                <img src="@/assets/images/web/home/header_cart.svg" style="width: 26px;" />
                            </router-link>
                        </a-badge>
                    </li>

                    <li class="header-menu-item">
                        <a-dropdown placement="bottomRight">
                            <router-link class="flex cen-center font-color-base" to="/member_v2">
                                <img :src="userInfo.profile_photo_url" v-if="userInfo.profile_photo_url"
                                    style="width:32px;border-radius:16px;" />
                                <img src="@/assets/images/web/user_info/user_girl.png"
                                    v-if="userInfo.profile_photo_url==''&&userInfo.sex==2" style="width:32px;" />
                                <img src="@/assets/images/web/user_info/user_boy.png"
                                    v-if="userInfo.profile_photo_url==''&&(userInfo.sex==1||userInfo.sex==0)"
                                    style="width:32px;" />
                                <img v-if="userInfo.vip_auth!=0&&userInfo.vip_get!=0"
                                    src="@/assets/images/web/user_info/vip-logo-small.svg"
                                    style="width:14px;margin-left: -12px;margin-top:20px;" />
                            </router-link>

                            <!-- 下拉菜单 -->
                            <a-menu slot="overlay" style="width:460px;border-radius: 2px;" class="user-info"
                                v-if="!test">
                                <a-menu-item style="height:95px;border-bottom:1px solid #F0F4F5;padding-top:28px;">
                                    <div style="width: 20%;display:inline-block;vertical-align:top;">
                                        <router-link to="/user">
                                            <img :src="userInfo.profile_photo_url" v-if="userInfo.profile_photo_url"
                                                style="width:50px;height:50px;border-radius: 25px;" />
                                            <img src="@/assets/images/web/user_info/user_girl.png"
                                                v-if="userInfo.profile_photo_url==''&&userInfo.sex==2"
                                                style="width:50px;height:50px;border-radius: 25px;" />
                                            <img src="@/assets/images/web/user_info/user_boy.png"
                                                v-if="userInfo.profile_photo_url==''&&(userInfo.sex==1||userInfo.sex==0)"
                                                style="width:50px;height:50px;border-radius: 25px;" />
                                        </router-link>
                                    </div>
                                    <div style="width: 22%;display:inline-block;">
                                        <p>{{userInfo.name}}</p>
                                        <router-link to="/member?menukey=6">
                                            <p v-if="userInfo.vip_auth==0" style="width:90%;" class="un-auth-status">
                                                <img style="width: 16px;height:16px;"
                                                    src="@/assets/images/web/user_info/auth-no.svg" />
                                                <router-link to="/member?menukey=6"><span
                                                        style="font-size: 14px;color: #666666;">未认证</span></router-link>
                                            </p>
                                        </router-link>
                                        <p v-if="userInfo.vip_auth!=0" style="width:90%;" class="auth-status">
                                            <img style="width: 16px;height:16px;"
                                                src="@/assets/images/web/user_info/auth-yes.svg" />
                                            <router-link to="/member?menukey=6"><span
                                                    style="font-size: 14px;">已认证</span></router-link>

                                        </p>
                                    </div>
                                    <div style="width: 50%;display:inline-block;">
                                        <p>ID: {{userInfo.user_id}}</p>
                                        <p class="un-auth-status" v-if="userInfo.phone==''" style="width:40%;">
                                            <img style="width: 16px;height:16px;"
                                                src="@/assets/images/web/user_info/phone-unbind.svg" />
                                            <router-link to="/member?menukey=9"><span
                                                    style="font-size: 14px;color: #666666;">未绑定</span></router-link>
                                        </p>
                                        <p class="auth-status" v-else style="width:40%;">
                                            <img style="width: 16px;height:16px;"
                                                src="@/assets/images/web/user_info/phone-bind.svg" />
                                            <router-link to="/member?menukey=9"><span
                                                    style="font-size: 14px;">已绑定</span></router-link>
                                        </p>
                                    </div>
                                    <div style="width: 15%;display:inline-block;vertical-align:top;">
                                        <span @click="goOutLogin">退出</span>
                                    </div>
                                </a-menu-item>
                                <a-menu-item style="text-align:center;" v-if="userInfo.vip_auth==0||userInfo.vip_get==0"
                                    class="un-auth">
                                    <div>
                                        <router-link :to="`/subscribe?vipType=1`">
                                            <p>
                                                <span>企业VIP</span>
                                                <span>开通</span>
                                            </p>
                                            <p style="font-size: 14px;font-weight: 400;color: #666666;">
                                                企业商用，免受侵权
                                            </p>
                                        </router-link>
                                    </div>
                                    <div>
                                        <router-link :to="`/subscribe?vipType=2`">
                                            <p>
                                                <span>个人VIP</span>
                                                <span>开通</span>
                                            </p>
                                            <p style="font-size: 14px;font-weight: 400;color: #666666;">
                                                个人用途自由使用
                                            </p>
                                        </router-link>
                                    </div>
                                </a-menu-item>
                                <a-menu-item v-if="userInfo.vip_auth!=0&&userInfo.vip_get!=0">
                                    <div
                                        style="width:90%;margin:0 auto;padding-top:20px;font-size: 16px;font-weight: 400;color: #333333;text-shadow: 0px 2px 8px rgba(195, 195, 195, 0.33);">
                                        <p>
                                            <span>VIP类型：</span><img
                                                src="@/assets/images/web/user_info/vip-logo-middle.svg" />
                                            <span v-if="userInfo.vip_auth==1"> 企业VIP</span>
                                            <span v-if="userInfo.vip_auth==2"> 个人VIP</span>
                                        </p>
                                        <p v-if="userInfo.vip_auth==1">授权范围：企业商用</p>
                                        <p v-if="userInfo.vip_auth==2">授权范围：仅限个人用途使用，不可企业商用</p>
                                        <router-link class="font-color-base" :to="`/subscribe`">
                                            <p
                                                style="line-height: 44px;background: #FF8220;box-shadow: 0px 2px 8px 0px rgba(195, 195, 195, 0.33);border-radius: 22px;text-align:center;">
                                                <span
                                                    style="font-size: 16px;font-weight: 500;color: #FFFFFF;text-shadow: 0px 2px 8px rgba(195, 195, 195, 0.33);">立即续费</span>
                                            </p>
                                        </router-link>
                                    </div>
                                </a-menu-item>
                                <a-menu-item style="padding-top:20px;">
                                    <div style="width:90; margin:0 auto;">
                                        <router-link class="font-color-base" :to="`/member?menukey=2`">
                                            <div class="user-operation">
                                                <img src="@/assets/images/web/user_info/favorite.svg" />
                                                <p>我的收藏</p>
                                            </div>
                                        </router-link>
                                        <router-link class="font-color-base" :to="`/member?menukey=4`">
                                            <div class="user-operation">
                                                <img src="@/assets/images/web/user_info/order.svg" />
                                                <p>我的订单</p>
                                            </div>
                                        </router-link>
                                        <router-link class="font-color-base" :to="`/member?menukey=7`">
                                            <div class="user-operation">
                                                <img src="@/assets/images/web/user_info/license.svg" />
                                                <p>授权管理</p>
                                            </div>
                                        </router-link>
                                        <router-link class="font-color-base" :to="`/member?menukey=8`">
                                            <div class="user-operation">
                                                <img src="@/assets/images/web/user_info/coupon.svg" />
                                                <p>优惠券</p>
                                            </div>
                                        </router-link>
                                    </div>
                                </a-menu-item>
                            </a-menu>
                            <a-menu slot="overlay" style="width:400px;border-radius: 2px;" class="user-info"
                                v-if="test">
                                <a-menu-item style="height:80px;border-bottom:1px solid #F0F4F5;padding-top:16px;">
                                    <div style="width: 20%;display:inline-block;vertical-align:top;">
                                        <router-link to="/user">
                                            <img :src="userInfo.profile_photo_url" v-if="userInfo.profile_photo_url"
                                                style="width:50px;height:50px;border-radius: 25px;" />
                                            <img src="@/assets/images/web/user_info/user_girl.png"
                                                v-if="userInfo.profile_photo_url==''&&userInfo.sex==2"
                                                style="width:50px;height:50px;border-radius: 25px;" />
                                            <img src="@/assets/images/web/user_info/user_boy.png"
                                                v-if="userInfo.profile_photo_url==''&&(userInfo.sex==1||userInfo.sex==0)"
                                                style="width:50px;height:50px;border-radius: 25px;" />
                                        </router-link>
                                    </div>
                                    <div style="width: 62%;display:inline-block;">
                                        <div>{{userInfo.name}}</div>
                                        <div style="margin-top:6px;">ID: {{userInfo.user_id}}</div>
                                    </div>
                                    <div style="width: 15%;display:inline-block;vertical-align:top;">
                                        <span @click="goOutLogin">退出</span>
                                    </div>
                                </a-menu-item>
                                <a-menu-item style="text-align:center;height: 190px;" class="un-auth-">
                                    <div class="block1">
                                        <router-link :to="`/package?id=3`">
                                            <div
                                                style="position: absolute; top: 15px;left:12px;color:#FFFFFF;font-size:16px;">
                                                超值套餐</div>
                                            <div style="position: absolute; top: 45px;left:12px;width: 140px; text-align: left;
                                            color:#FFFFFF;font-size:14px;">企业批量采买授权低至<br>6.5折，全曲库范围选<br>曲，支持方案定制</div>
                                            <div style="position: absolute; top: 132px;left:12px;width: 124px;height: 24px;background: #FFFFFF;
                                            border-radius: 12px;color:#F78989;line-height: 24px;">购买</div>
                                        </router-link>
                                    </div>
                                    <div class="block4">
                                        <div class="block2">
                                            <!-- <router-link :to="`/subscribe_v2?vipType=1`"> -->
                                            <div @click="goSubscribe(1)">
                                                <div
                                                    style="position: absolute; top: 15px;left:12px;color:#FFFFFF;font-size:16px;">
                                                    企业会员
                                                </div>
                                                <div class="vipdesc" v-if="userInfo.company_vip==0">臻享会员专区+音效库作品</div>
                                                <div class="vipdesc" v-if="userInfo.company_vip==1">到期时间：{{coop1}}</div>
                                                <div class="btn1" v-if="userInfo.company_vip==0">开通</div>
                                                <div class="btn1" v-if="userInfo.company_vip==1">续费</div>
                                            <!-- </router-link> -->
                                            </div>
                                        </div>
                                        <div class="block3">
                                            <!-- <router-link :to="`/subscribe_v2?vipType=2`"> -->
                                            <div @click="goSubscribe(2)">
                                                <div
                                                    style="position: absolute; top: 15px;left:12px;color:#FFFFFF;font-size:16px;">
                                                    个人会员
                                                </div>
                                                <div class="vipdesc" v-if="userInfo.person_vip==0">臻享会员专区+音效库作品</div>
                                                <div class="vipdesc" v-if="userInfo.person_vip==1">到期时间：{{coop2}}</div>
                                                <div class="btn2" v-if="userInfo.person_vip==0">开通</div>
                                                <div class="btn2" v-if="userInfo.person_vip==1">续费</div>
                                            </div>
                                            <!-- </router-link> -->
                                        </div>
                                    </div>
                                </a-menu-item>
                                <a-menu-item style="padding-top:10px;">
                                    <div style="width:90; margin:0 auto;">
                                        <router-link class="font-color-base" :to="`/member_v2?menukey=2`">
                                            <div class="user-operation">
                                                <img src="@/assets/images/web/user_info/我的收藏.png"/>
                                                <p>我的收藏</p>
                                            </div>
                                        </router-link>
                                        <router-link class="font-color-base" :to="`/member_v2?menukey=4`">
                                            <div class="user-operation">
                                                <img src="@/assets/images/web/user_info/我的订单.png" />
                                                <p>我的订单</p>
                                            </div>
                                        </router-link>
                                        <router-link class="font-color-base" :to="`/member_v2?menukey=3`">
                                            <div class="user-operation">
                                                <img src="@/assets/images/web/user_info/授权记录.png"/>
                                                <p>我的会员</p>
                                            </div>
                                        </router-link>
                                        <router-link class="font-color-base" :to="`/member_v2?menukey=11`">
                                            <div class="user-operation">
                                                <img src="@/assets/images/web/user_info/优惠券.png"/>
                                                <p>我的套餐</p>
                                            </div>
                                        </router-link>
                                        <router-link class="font-color-base" :to="`/member_v2?menukey=7`">
                                            <div class="user-operation">
                                                <img src="@/assets/images/web/user_info/授权管理.png" />
                                                <p>授权管理</p>
                                            </div>
                                        </router-link>
                                    </div>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </li>
                </template>
                <!-- 未登录 -->
                <template v-else>
                    <li class="header-menu-item item" @click="login">登录/注册</li>
                </template>
            </ul>
        </div>

        <header-mobile class="hidden-md visible-sm"></header-mobile>
        <register-guide v-if="showGuide" @cancel="close" @ok="onok"></register-guide>
    </div>

</template>

<script>
    import { Badge, Dropdown, Menu } from 'ant-design-vue'
    import HeaderMobile from './HeaderMobile.vue'

    import HeaderMixins from './header_mixins'
    import RegisterGuide from "@/components/dialog/RegisterGuide";
    const MenuList = function () {
        return [
            {
                id: '2',
                name: this.$t('headerMenu.fine'),
                to: '/musicFine'
            },
            {
                id: '1',
                name: this.$t('headerMenu.allMusic'),
                to: '/allmusic'
            },
            {
                id: "6",
                name: '音效',
                to: "/allSound",
            },
            {
                id: "7",
                name: '会员专区',
                to: "/vipmusic",
            },
            {
                id: '4',
                name: '歌单',
                to: '/playlist'
            },
            {
                id: "3",
                name: this.$t("headerMenu.vipMeal"),
                to: "/package",
            },
            {
                id: '5',
                name: this.$t('headerMenu.supplier'),
                to: '/supplier'
            },
            // {
            //     id: '8',
            //     name: this.$t('headerMenu.authorize'),
            //     to: '/subscribe_introduce'
            // },

        ]
    }

    const UserMenu = function () {
        return [
            {
                id: '2',
                to: `/user?menukey=${this.$val.UserMenu.collect}`,
                name: '我的收藏'
            },
            {
                id: '1',
                to: `/user?menukey=${this.$val.UserMenu.order}`,
                name: '我的订单'
            },
            {
                id: '3',
                to: `/user?menukey=${this.$val.UserMenu.operation}`,
                name: '授权管理'
            },
            {
                id: '4',
                to: `/user/account?menukey=${this.$val.UserMenu.basic}`,
                name: '优惠券'
            }
        ]
    }

    export default {
        data() {
            return {
                showGuide: !this.$store.state.login,
                test: true,
                coop1: '',
                coop2: '',
            }
        },

        computed: {
            Menu() {
                return MenuList.call(this)
            },

            UserMenu() {
                return UserMenu.call(this)
            },
            userInfo() {
                this.setUserInfo(this.$store.state.userInfo);
                return Object.assign({}, this.$store.state.userInfo);
            },
        },
        
        watch: {
            userInfo(newVal, oldVal) {
                this.setUserInfo(newVal);
            }
        },
        created() {
            //this.userInfo.phone = ""; //todo

        },
        mounted() {
        },
        mixins: [HeaderMixins],

        components: {
            ABadge: Badge,
            ADropdown: Dropdown,
            AMenu: Menu,
            AMenuItem: Menu.Item,
            HeaderMobile,
            RegisterGuide
        },

        methods: {
            goSubscribe(t){
                // this.$router.push({path:'/musicFine?id=2'});
                this.$router.replace({path:'/subscribe_v2?vipType='+t});
            },
            setUserInfo(data){
                if (data['company_vip_coop'] != null) {
                    var date = new Date(parseInt(data['company_vip_coop']) * 1000);
                    var y = date.getFullYear();
                    var m = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
                    var d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                    this.coop1 = y + "-" + m + "-" + d;
                }
                if (data['person_vip_coop'] != null) {
                    var date2 = new Date(parseInt(data['person_vip_coop']) * 1000);

                    var y2 = date2.getFullYear();
                    var m2 = (date2.getMonth() + 1 < 10 ? '0' + (date2.getMonth() + 1) : date2.getMonth() + 1);
                    var d2 = date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate();
                    this.coop2 = y2 + "-" + m2 + "-" + d2;
                }
            },
            Chatra() {
                window.Chatra('openChat', true)
            },
            close() {
                // 关闭弹出层
                this.showGuide = false;

            },
            onok() {
                this.showGuide = false;
                this.changeLoginAndRegisterDialog(true);
                this.changeShowRegister(true);

            }
        }
    }
</script>
<style lang="scss" scoped>
    ::v-deep {
        .search {
            .ant-input {
                height: $header-height;
                line-height: $header-height;
                padding-left: 36px;
                border: none;
                background-color: transparent !important;

                &:hover,
                &:focus {
                    border-color: transparent;
                    box-shadow: none;
                }
            }

            .ant-input-suffix {
                display: inline-block;
                height: 20px;
                width: 20px;
                line-height: 21px;
                text-align: center;
                color: #fff;
                background-color: $black-1;
                border-radius: 100%;
                font-size: 12px;

                .ant-input-search-icon {
                    display: none;
                }
            }

            .search-box {
                position: absolute;
                left: 70%;
                right: 0;
                background: #fff;
                transition: all linear 0.2s;
                z-index: 10;

                .search {
                    position: relative;
                    height: $header-height;
                    line-height: $header-height;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.55);
                    z-index: 1000;
                }
            }
        }

        .ant-input-affix-wrapper .ant-input-prefix {
            left: 0 !important;
            cursor: pointer;
            z-index: 100;
        }
    }

    @keyframes blink {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 1;
        }

        50.01% {
            opacity: 0;
        }

        /* 注意这里定义50.01%立刻透明度为０，可以设置闪烁效果 */
        100% {
            opacity: 0;
        }
    }

    .intr-info {
        width: 93.6%;
        margin: 0 auto;
        margin-top: 24px;

        .intr-info-item {
            display: inline-block;
            width: 22.99%;
            text-align: center;
            background: #FFFFFF;
            box-shadow: 0px 2px 6px 0px rgba(198, 198, 198, 0.23);
            border-radius: 2px;
            border: 1px solid #EEEEEE;

            div:nth-child(1) {
                height: 76px;
                background: #DEEBF7;
                box-shadow: 0px 2px 6px 0px rgba(198, 198, 198, 0.23);
                border-radius: 2px 2px 0px 0px;
                text-shadow: 0px 2px 6px rgba(198, 198, 198, 0.23);

                h3 {
                    height: 25px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #333333;
                    padding-top: 12px;
                }

                p {
                    height: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    padding-top: 6px;
                }
            }

            div:nth-child(2) {
                p:nth-child(1) {
                    margin-top: 14px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #999999;
                    text-shadow: 0px 2px 6px rgba(198, 198, 198, 0.23);
                }

                p:nth-child(2) {
                    margin-top: 7px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    text-shadow: 0px 2px 6px rgba(198, 198, 198, 0.23);
                }

                a p:nth-child(1),
                p:nth-child(3) {
                    line-height: 30px;
                    width: 90%;
                    margin: 0 auto;
                    margin-top: 10px;
                    background: linear-gradient(180deg, #78ABE5 0%, #6B8FF2 100%);
                    box-shadow: 0px 2px 6px 0px rgba(198, 198, 198, 0.23);
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-shadow: 0px 2px 8px rgba(195, 195, 195, 0.33);
                }
            }

            div:nth-child(3) {
                height: 60px;
                margin-top: 14px;
                background: #F8F8F8;
                box-shadow: 0px 2px 6px 0px rgba(198, 198, 198, 0.23);
                border-radius: 0px 0px 2px 2px;
                padding-top: 10px;

                p {
                    width: 90%;
                    margin: 0 auto;
                    text-align: center;
                    height: 20px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #999999;
                    text-shadow: 0px 2px 6px rgba(198, 198, 198, 0.23);
                }
            }
        }

        .intr-info-item:not(:nth-child(1)) {
            margin-left: 2.67%;
        }

        .intr-info-item:hover {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
            -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
            -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
            -o-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        }

        .intr-info-item:nth-child(2) {
            div:nth-child(1) {
                background: #78ABE5;
                box-shadow: 0px 2px 8px 0px rgba(195, 195, 195, 0.33);
                border-radius: 2px 2px 0px 0px;

                h3 {
                    color: #FFFFFF;
                }

                p {
                    color: #FFFFFF;
                }
            }
        }

        .intr-info-item:nth-child(3) {
            div:nth-child(1) {
                background: #6BA2F2;
                box-shadow: 0px 2px 8px 0px rgba(195, 195, 195, 0.33);
                border-radius: 2px 2px 0px 0px;

                h3 {
                    color: #FFFFFF;
                }

                p {
                    color: #FFFFFF;
                }
            }
        }

        .intr-info-item:nth-child(4) {
            div:nth-child(1) {
                background: #6E8CEA;
                box-shadow: 0px 2px 8px 0px rgba(195, 195, 195, 0.33);
                border-radius: 2px 2px 0px 0px;

                h3 {
                    color: #FFFFFF;
                }

                p {
                    color: #FFFFFF;
                }
            }
        }
    }

    .user-info {
        .avarator {
            flex: 0 0 32px;
            width: 32px;
            height: 32px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 5px;
        }
    }

    .user-operation {
        width: 20%;
        text-align: center;
        display: inline-block;

        img {
            opacity: 0.8;
        }

        img:hover {
            opacity: 1;
        }
    }

    .un-auth {

        div:nth-child(1),
        div:nth-child(2) {
            text-align: left;
            width: 45%;
            display: inline-block;
            vertical-align: top;
            background: #FFFFFF;
            border-radius: 5px;
            padding-top: 13px;
            padding-left: 13px;
            margin: 24px 2.5% 0px 2.5%;
            box-shadow: 0px 2px 8px 0px rgba(195, 195, 195, 0.33);
            -webkit-box-shadow: 0px 2px 8px 0px rgba(195, 195, 195, 0.33);
            -moz-box-shadow: 0px 2px 8px 0px rgba(195, 195, 195, 0.33);
            -o-box-shadow: 0px 2px 8px 0px rgba(195, 195, 195, 0.33);

            span:nth-child(1) {
                font-size: 16px;
                font-weight: 500;
                color: #333333;
            }

            span:nth-child(2) {
                padding: 4px 10px;
                border-radius: 10px;
                border: 1px solid #FF7B13;
                font-size: 12px;
                font-weight: 400;
                color: #FF7B13;
                margin-left: 40%;
            }
        }
    }

    .un-auth-status,
    .auth-status {
        border: 1px solid red;
        border-radius: 10px;
        border: 1px solid #CCCCCC;
        font-weight: 400;
        color: #999999;
        text-align: center;
    }

    .auth-status {
        border: 1px solid #FF7B13;
        color: #FF7B13;
    }

    .ant-dropdown-menu-item-active {
        background: #F8FAFB;
    }

    .ng-header {
        background-color: #15110F;

        &.search-open {
            .search-box {
                // width: 95%;
                left: 2%;
            }

            .header-menu-left {
                opacity: 0;
            }

            .mask {
                position: absolute;
                top: -100000px;
                bottom: -100000px;
                left: -100000px;
                right: -100000px;
                z-index: 99;
            }
        }

        .ng-header-content {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .logo {
            width: 170px;
            height: 39px;

            >img {
                height: 100%;
            }
        }

        .header-link {
            padding: 0 20px;
            color: $font-base-color;
            color: #fff;

            &:hover {
                color: #FF7B13;
            }

            &.router-link-active {
                color: #FF7B13;
                background: rgba(0, 0, 0, 0.85);
            }
        }

        .avarator {
            flex: 0 0 32px;
            width: 32px;
            height: 32px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 5px;
        }

        .search-box {
            position: absolute;
            left: 70%;
            right: 0;
            background: #fff;
            transition: all linear 0.2s;
            z-index: 10;

            .search {
                position: relative;
                height: $header-height;
                line-height: $header-height;
                border-bottom: 1px solid rgba(0, 0, 0, 0.55);
                z-index: 1000;
            }
        }

        .header-menu {
            display: flex;
            flex-direction: row;
            align-items: center;
            opacity: 1;
            transition: all linear 0.3s;

            .header-menu-item {
                display: flex;
                align-items: center;
                height: $header-height;
                line-height: $header-height;
                padding: 0 10px;
                font-size: 20px;
                cursor: pointer;

                .subscribe-box {
                    display: inline-block;

                    font-weight: 500;

                    span:nth-child(1) {
                        color: #fff;
                        font-size: 16px;
                        margin-left: 5px;

                        &:hover {
                            color: #FF7B13;
                        }
                    }

                    span:nth-child(2) {
                        color: red;
                        vertical-align: super;
                        font-size: 12px;
                        font-weight: 500;
                        animation: blink 1.2s linear infinite;
                    }
                }
            }

            .item {
                display: flex;
                align-items: center;
                height: 65px;
                padding: 0 16px;
                font-size: 18px;
                cursor: pointer;
                border-radius: 36px;
                height: 36px;
                width: 104px;
                background: #ff7b13;
                background: linear-gradient(270deg, rgb(240, 107, 3) -2%, rgb(234, 39, 224) 100%);
                color: #fff;
                font-size: 16px;
                text-align: center;
            }

            &.right {
                margin-right: -10px;
            }
        }

        .header-icon {
            font-size: 28px;
            color: #fff;

            &:hover {
                color: #FF7B13;
            }
        }

    }

    @media screen and (min-width: $screen-width-md) {
        .ng-header {
            .header-menu-left {
                .header-menu-item {
                    padding: 0;
                    font-size: 14px;
                }

                .header-link {
                    padding: 0 10px;
                }
            }

            .search-box {
                left: 73%;
            }
        }
    }

    @media screen and (min-width: $screen-width-lg) {
        .ng-header {
            .header-menu-left {
                .header-link {
                    padding: 0 20px;
                }

                .header-menu-item {
                    font-size: 18px;
                }
            }
        }
    }

    .block1 {
        position: absolute;
        margin-top: 8px;
        margin-left: 0px;
        padding: 0;
        width: 160px;
        height: 168px;
        background: linear-gradient(270deg, #FAAF7D 0%, #F4579A 100%);
        border-radius: 4px;
        vertical-align: top;
        left: 16px;


    }

    .block2 {
        width: 200px;
        height: 80px;
        background: linear-gradient(270deg, #65C6EF 0%, #429BEC 100%, #409AEB 100%);
        border-radius: 4px;
    }

    .block3 {
        position: relative;
        margin-top: 8px;
        width: 200px;
        height: 80px;
        background: linear-gradient(270deg, #EA7CF6 0%, #5882EF 100%);
        border-radius: 4px;
    }

    .block4 {
        position: absolute;
        margin-top: 8px;
        width: 160px;
        height: 168px;
        border-radius: 4px;
        vertical-align: top;
        left: 184px;
    }

    .vipdesc {
        position: absolute;
        top: 45px;
        left: 12px;
        width: 140px;
        text-align: left;
        color: #FFFFFF;
        font-size: 14px;
    }

    .btn1 {
        position: absolute;
        top: 15px;
        left: 147px;
        width: 44px;
        height: 20px;
        background: #FFFFFF;
        border-radius: 10px;
        color: #54B1EE;
        line-height: 20px;
    }

    .btn2 {
        position: absolute;
        top: 15px;
        left: 147px;
        width: 44px;
        height: 20px;
        background: #FFFFFF;
        border-radius: 10px;
        color: #B182F3;
        line-height: 20px;
    }
</style>