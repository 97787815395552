<template>
    <div class="mark" @mousewheel="mousewheel">
        <div class="alert">
            <!-- <div>Spark Music</div> -->
            <img src="@/assets/images/web/home/window.png"/>
            <!-- <div>新人专享</div>
            <div>注册即享</div>
            <div>
                <span>全场音乐</span>
                <span>6</span>
                <span>折券一张</span>
            </div> -->
            <img class="close" @click="cancel" src="@/assets/images/web/home/close.png" />
            <img class="btn" @click="ok" src="@/assets/images/web/home/btn.png" />
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        methods: {
            mousewheel(e) {
                e.preventDefault();
            },
            ok(){
                this.$emit('ok')
            },
            // 点击取消
            cancel() {
                this.$emit('cancel')
            },
        }
    };
</script>

<style scoped>
    .mark {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .alert {
        /* background: #fff; */
        width: 380px;
        height: 477px;
        position: relative;
    }
    .btn{
        position: absolute;
        width: 274px;
        height: 90px;
        text-align: center;
        left:53px;
        top: 380px;
        cursor:pointer;
    }
    .close{
        position: absolute;
        width: 26px;
        height: 26px;
        text-align: center;
        left:350px;
        top: 40px;
        cursor:pointer;
    }
</style>